import { useEffect, useState } from 'react';

/**
 * @function useWindowSize
 * @description function which allows the easy use of the screen width and height on change of them.
 * @return {width: number, height: number}
 */
export default function useWindowSize(): { width: number, height: number } {
   const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
   });

   useEffect(
      () => {
         // Handler to call on window resize
         function handleResize() {
            // Set window width/height to state
            setWindowSize({
               width: window.innerWidth,
               height: window.innerHeight,
            });
         }

         // Add event listener
         window.addEventListener('resize', handleResize);

         // Call handler right away so state gets updated with initial window size
         handleResize();

         // Remove event listener on cleanup
         return () => window.removeEventListener('resize', handleResize);
      },
      [], // Empty array ensures that effect is only run on mount
   );

   return windowSize;
}
