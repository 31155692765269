import React, { useEffect, useMemo, useState } from 'react';

import './styles.scss';
import DocumentContent from './DocumentContent';
import IFrameContent from './IFrameContent';
import ImageContent from './ImageContent';
import VideoContent from './VideoContent';
import WebsiteContent from './WebsiteContent';

import { Icon } from '..';
/* webpack-strip-block:removed */
import { NavLink, useLocation } from 'react-router-dom';

import { getTranslation, removeUrlParameter, useGlobalData } from '../../util';
import TopButtons from './TopButtons';

/**
 * @class Popup
 * @classdesc displays a Popup with its asset content.
 * @hideconstructor
 */
export default function Popup(): JSX.Element | null {
   const { content } = useGlobalData();
   const [nextPrevUrl, setNextPrevUrl] = useState<{
      next: string | undefined | {pathname: string, search: string};
      prev: string | undefined | {pathname: string, search: string};
   }>({
      next: undefined,
      prev: undefined,
   });
   const location = useLocation();

   /* webpack-strip-block:removed */
   /* NON_SPEAKING_URLS-START */
   const url = {
      pathname: `${window.location.search.substr(0, window.location.search.lastIndexOf('%2F'))}${removeUrlParameter(window.location.search, 'path').replace(/\?/g, '&')}`,
   };
   /* NON_SPEAKING_URLS-END */

   const handleButtonClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      /* webpack-strip-block:removed */
   };

   useEffect(() => {
      try {
         const masonry = document.getElementById('masonry');

         if (content.popup && masonry && masonry.children.length > 1) {
            let nextItem = document.querySelector(`#masonry li[data-index="${content.popup.index + 1 > masonry.children.length - 1 ? 0 : content.popup.index + 1}"]`)?.firstChild as HTMLAnchorElement;
            let prevItem = document.querySelector(`#masonry li[data-index="${content.popup.index - 1 < 0 ? masonry.children.length - 1 : content.popup.index - 1}"]`)?.firstChild as HTMLAnchorElement;

            let index = content.popup.index;
            let loops = 0;

            while (nextItem.parentElement?.getAttribute('data-uid') === content.popup.uid) {
               nextItem = document.querySelector(`#masonry li[data-index="${index + 1 > masonry.children.length - 1 ? 0 : index + 1}"]`)?.firstChild as HTMLAnchorElement;
               index++;
               loops++;

               if (index > masonry.children.length) {
                  index = 0;
               }

               if (loops > masonry.children.length) {
                  break;
               }
            }

            index = content.popup.index;
            loops = 0;

            while (prevItem.parentElement?.getAttribute('data-uid') === content.popup.uid) {
               prevItem = document.querySelector(`#masonry li[data-index="${index - 1 < 0 ? masonry.children.length - 1 : index - 1}"]`)?.firstChild as HTMLAnchorElement;
               index--;

               if (index < 0) {
                  index = masonry.children.length - 1;
               }

               if (loops > masonry.children.length) {
                  break;
               }
            }

            if (masonry && masonry.children.length > 1) {
               /* webpack-strip-block:removed */
               /* NON_SPEAKING_URLS-START */
               const baseURL = document.baseURI.slice(0, -1);
               setNextPrevUrl({
                  next: {
                     pathname: nextItem.href.replace(baseURL, '').replace('/index.html', ''),
                     search: '',
                  },
                  prev: {
                     pathname: prevItem.href.replace(baseURL, '').replace('/index.html', ''),
                     search: '',
                  },
               });
               /* NON_SPEAKING_URLS-END */
            } else {
               setNextPrevUrl({
                  next: undefined,
                  prev: undefined,
               });
            }
         }
         /* webpack-strip-block:removed */
      } catch (error) {
         console.error(error);
      }

      return () => {
         /* webpack-strip-block:removed */
      };
   }, [content.popup]);

   if (!content.popup) {
      return useMemo(() => null, [
         content.popup,
         nextPrevUrl,
      ]);
   }

   return useMemo(() => (
      <article
         id='popup'
         className={`${content.popup?.type}`}
      >
         <TopButtons
            {...url}
         />
         <div
            className='inner'
            // we need the following line to force a rerender of the components below
            key={content.popup?.uid}
         >
            {(content.popup?.type?.toLowerCase() === 'image' || content.popup?.type === 'Bild') &&
               <ImageContent {...content.popup} />
            }
            {(content.popup?.type?.toLowerCase() === 'video' || content.popup?.type === 'Video') &&
               <VideoContent {...content.popup} />
            }
            {(content.popup?.type?.toLowerCase() === 'website' || content.popup?.type?.toLowerCase() === 'unknown') &&
               <WebsiteContent {...content.popup} />
            }
            {(content.popup?.type?.toLowerCase() === 'document' || content.popup?.type === 'PDF') &&
               <DocumentContent {...content.popup} />
            }
            {(content.popup?.type?.toLowerCase() === 'iframe') &&
               <IFrameContent {...content.popup} />
            }
         </div>
         {nextPrevUrl.prev &&
            <NavLink
               role='button'
               className='previous'
               aria-label={getTranslation()?.lightbox.previousSlide}
               onClick={handleButtonClick}
               to={nextPrevUrl.prev}
            >
               <Icon
                  icon='chevron-right'
               />
            </NavLink>
         }
         {nextPrevUrl.next &&
            <NavLink
               role='button'
               className='next'
               aria-label={getTranslation()?.lightbox.previousSlide}
               onClick={handleButtonClick}
               to={nextPrevUrl.next}
            >
               <Icon
                  icon='chevron-right'
               />
            </NavLink>
         }
      </article>
   ), [
      content.popup,
      nextPrevUrl,
   ]);
}
