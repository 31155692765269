import React, { useEffect, useRef, useState } from 'react';

import { NavLink, Route, useLocation } from 'react-router-dom';

import './styles.scss';

import { Icon } from '../..';
import { MainMenuItem } from '../interfaces';

import { getURLByTitleAndBreadcrumb, makeURLFriendly, removeUrlParameter } from '../../../util';
import StateOnRouteChange from './StateOnRouteChange';

/* webpack-strip-block:removed */

/**
 * @class MenuItem
 * @classdesc recursivly builds the tree of menu items.
 * @hideconstructor
 */
export default function MenuItem(props: MainMenuItem): JSX.Element {
   const [closed, setClosed] = useState(true);
   const location = useLocation();

   /* webpack-strip-block:removed */

   /* NON_SPEAKING_URLS-START */
   const url = {
      pathname: `?path=${getURLByTitleAndBreadcrumb(props.uid, props.breadcrumb).replace('/', '').replace(/\//g, '%2F')}${removeUrlParameter(window.location.search, 'path').replace(/\?/g, '&')}`,
      search: undefined,
   };
   /* NON_SPEAKING_URLS-END */

   const navRef = useRef<HTMLAnchorElement>(null);

   const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (e.currentTarget.classList.contains('active')) {
         if (e.currentTarget?.parentElement?.classList.contains('has-items')) {
            setClosed(!closed);
         }
      }

      /* webpack-strip-block:removed */
   };

   useEffect(
      () => {
         document.querySelectorAll('#main-menu > ul > li > a')?.forEach((element) => {
            element.removeAttribute('tabindex');
         });
      },
      [window.location.href],
   );

   useEffect(() => {
      navRef.current?.parentElement?.setAttribute('data-closed', `${closed}`);
   }, [
      closed,
      navRef.current?.classList,
   ]);

   /* webpack-strip-block:removed */

   /* NON_SPEAKING_URLS-START */
   useEffect(() => {
      const pathUids = window.location.search.split('path=')[1]?.split('&')[0]?.split('%2F');

      if (pathUids && pathUids.indexOf(props.uid) !== -1) {
         setClosed(false);
      }
   }, [window.location.search]);
   /* NON_SPEAKING_URLS-END */

   return (
      <>
         {/* webpack-strip-block:removed */}
            <StateOnRouteChange
               anchor={navRef.current}
               {...props}
            />
            {/* webpack-strip-block:removed */}
         <NavLink
            title={props.title}
            ref={navRef}
            to={url}
            isActive={(m) => {
               /* webpack-strip-block:removed */

               /* NON_SPEAKING_URLS-START */
               const pathUids = window.location.search.split('path=')[1]?.split('&')[0]?.split('%2F');

               if (pathUids && pathUids.indexOf(props.uid) !== -1) {
                  navRef.current?.parentElement?.setAttribute('current', '');

                  // exact
                  if (pathUids && pathUids[pathUids?.length - 1] === props.uid) {
                     (document.querySelector('#main-menu [aria-current="page"]') as HTMLAnchorElement)?.removeAttribute('aria-current');
                     navRef.current?.setAttribute('aria-current', 'page');

                     // handle scroll menu item to top
                     if (navRef.current) {
                        const firstUL = document.querySelector('#main-menu > ul') as HTMLLIElement;

                        if (firstUL) {
                           firstUL.scrollTop = navRef.current.offsetTop;
                        }
                     }
                  }

                  return true;
               }
               /* NON_SPEAKING_URLS-END */
               navRef.current?.parentElement?.removeAttribute('current');

               return false;
            }}
            onClick={handleLinkClick}
         >
            <span><span>{props.title}</span></span>
            {props.items?.length !== 0 &&
               <Icon
                  icon='chevron-right'
               />
            }
         </NavLink>
         {!!props.items?.length && !closed &&
            <ul>
               {props.items?.map((item, index) => {
                  // only render if they have assets or children
                  if (item.items?.length || item.assets?.length) {
                     return (
                        <li
                           key={`${item.uid}${index}`}
                           className={`${props.items && index === props.items.length - 1 ? 'last ' : ''}${item.items?.length ? 'has-items ' : ''}`}
                           data-closed={true}
                        >
                           <MenuItem
                              {...item}
                              setAssets={props.setAssets}
                           />
                        </li>
                     );
                  }

                  return null;
               },
               )}
            </ul>
         }
      </>
   );
}
