import React, { useEffect, useState } from 'react';

import { dispatchLogEvent } from '../../plugins';

import { getTranslation } from '../../util';
import { PopupAsset } from '../../util/ContentHandler/createDataObjectFromSSP/interfaces';

/**
 * @class WebsiteContent
 * @classdesc displays the website content inside of the popup.
 * @hideconstructor
 */
export default function WebsiteContent(props: PopupAsset): JSX.Element {
   const [href, setHref] = useState(props.data.url);

   const handleLinkClick = () => {
      /* webpack-strip-block:removed */
   };

   // the ssp doesn't give us a useable url, so here we go.
   useEffect(() => {
      const controller = new AbortController();

      (async () => {
         try {
            if (props.data.url) {
               const response = await fetch(props.data.url, {
                  method: 'GET',
                  mode: 'cors', // no-cors, *cors, same-origin
                  credentials: 'same-origin', // include, *same-origin, omit
                  signal: controller.signal,
               });
               const text = await response.text();

               setHref(text.split('URL=')[1]);
            }
         } catch(err) {
            if (err.name === 'AbortError') {
               console.warn('Fetch aborted');
            } else {
               console.error(err);
            }
         }
      })();

      return () => {
         controller.abort();
      };
   }, []);

   return (
      <>
         <h1>{props.data.headline}</h1>
         <p>
            {getTranslation()?.lightbox.message_before} (<a
               href={`${href}`}
               target='_blank'
               rel='noreferrer'
               onClick={handleLinkClick}
            >
               {href}
            </a>) {getTranslation()?.lightbox.message_after}
         </p>
         <a
            href={`${href}`}
            target='_blank'
            rel='noreferrer'
            onClick={handleLinkClick}
         >
            {getTranslation()?.lightbox.openNewTab}
         </a>
      </>
   );
}
