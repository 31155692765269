import React from 'react';
import { Helmet } from 'react-helmet';
import { Home as HomeInterface } from '../../util/ContentHandler/interfaces';
import { useGlobalData, useWindowSize } from '../../../../util';

import './styles.scss';

/**
 * @class Home
 * @classdesc displays the home page in the content area.
 * @hideconstructor
 */
export default function Home() {
   const { content } = useGlobalData();
   const backgroundImage = `url(${
      useWindowSize().width > 641
         ? content.home?.background?.large
         : content.home?.background?.small || ''
   })`;

   if (!content.home) {
      return null;
   }

   const homeData: HomeInterface = {
      headline: content.home?.claim,
   };
   // theme "hatebur" always shows the company name for overline
   homeData.preheadline = 'Hatebur';

   return (
      <>
         <Helmet>
            <title>{homeData?.headline}</title>
         </Helmet>
         <section
            id='home'
            style={{
               backgroundImage,
            }}
         >
            {(homeData.headline || homeData.preheadline) && (
               <h1 className='wrapper'>
                  {homeData.preheadline && (
                     <div className='overline'>{homeData?.preheadline}</div>
                  )}
                  {homeData.headline && (
                     <div className='headline'>{homeData.headline}</div>
                  )}
               </h1>
            )}
         </section>
      </>
   );
}
