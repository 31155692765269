import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '..';
import { AddToCartButton, dispatchLogEvent } from '../../plugins';
import CartItem from '../../plugins/Cart/CartItem/interfaces';
import { getTranslation, useGlobalData, useWindowSize } from '../../util';

export default function TopButtons(url: { pathname: string, search: string }): JSX.Element {
   const { width } = useWindowSize();
   const { content, state } = useGlobalData();

   const handleClose = () => {
      /* webpack-strip-block:removed */
      state.set.content({
         ...content,
         popup: undefined,
      });
   };

   return (
      <div className='buttons'>
         {/* We have to change the layout on size with js instead of css, to preserve the tab order */}
         {width < 641 &&
            <>
               {/* CART-START */}
               <AddToCartButton
                  {...{
                     ...content.popup as CartItem,
                     item: undefined,
                  }}
                  type={content.popup?.data?.type || content.popup?.type || ''}
               />
               {/* CART-END */}
               <NavLink
                  role='button'
                  className='close-button'
                  aria-label={getTranslation()?.misc.close}
                  onClick={handleClose}
                  to={url}
               >
                  <Icon
                     icon='close'
                  />
               </NavLink>
            </>
         }
         {width >= 641 &&
            <>
               <NavLink
                  role='button'
                  className='close-button'
                  aria-label={getTranslation()?.misc.close}
                  onClick={handleClose}
                  to={url}
               >
                  <Icon
                     icon='close'
                  />
               </NavLink>
               {/* CART-START */}
               <AddToCartButton
                  {...{
                     ...content.popup as CartItem,
                     item: undefined,
                  }}
                  type={content.popup?.data?.type || content.popup?.type || ''}
               />
               {/* CART-END */}
            </>
         }
      </div>
   );
}
