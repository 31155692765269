import React, { useEffect } from 'react';
/* webpack-strip-block:removed */
import './styles.scss';

import { getTranslation, useGlobalData, useSettings } from '../../util';
import { Language, LanguageName } from '../../enums';

export default function LanguageOverlayContent(): JSX.Element {
   const createLanguageSelectors = (): Array<JSX.Element> => {
      const { language } = useSettings();
      const { content, state } = useGlobalData();
      const availableLanguage = Object.keys(Language);
      const array: Array<JSX.Element> = [];

      for (let i = 0; i < availableLanguage.length / 2; i++) {
         const handleLanguageSwitch = () => {
            language.set(i);
            state.set.content({
               ...content,
               menuOverlay: undefined,
            });
         };

         array.push(
            <div key={i}>
               <input
                  id={`language-selector-${i}`}
                  type='radio'
                  name='language-selector'
                  value={i}
                  aria-checked={i === language.value}
                  defaultChecked={i === language.value}
                  onChange={handleLanguageSwitch}
               />
               <label
                  htmlFor={`language-selector-${i}`}
               >
                  {LanguageName[i]}
               </label>
            </div>,
         );
      }

      return array;
   };

   /* webpack-strip-block:removed */

   return (
      <>
         <h1
            id='cart-overlay-headline'
         >
            {getTranslation()?.language.title}
         </h1>
         <fieldset
            className='language-sector'
            aria-label='language selector'
         >
            {createLanguageSelectors()}
         </fieldset>
      </>
   );
}
