import DEFAULT_CONTEXT_TYPE from './interfaces';

const DEFAULT_CONTEXT = {
   content: {
      mainMenu: [],
   },
   state: {
      value: {
         pageStateValue: () => console.warn('no setter defined'),
      },
      set: {
         menuOpen: false,
      },
   },
} as unknown as DEFAULT_CONTEXT_TYPE;
export default DEFAULT_CONTEXT;
