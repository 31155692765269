import React, { useState } from 'react';

import Layer from '../Layer';
import { Language } from '../../../../enums';
import { TEXTS } from './texts';
import { textToJSX } from './util';
import { useSettings } from '../../../../util';

import './styles.scss';
import { Icon } from '../../../../components';

/**
 * Imprint
 * @param props (see ./interfaces.ts for details)
 */
export default function Imprint() {
   const [layerActive, setLayerActive] = useState(false); // Determines whether the imprint layer is active
   const { language } = useSettings();
   const languageKey = Language[language.value].toLowerCase();

   return (
      <>
         <Layer
            active={layerActive}
            closeFn={() => setLayerActive(false)}
            classes='imprint'
         >
            {textToJSX(TEXTS[languageKey in TEXTS ? languageKey : 'en'])}
         </Layer>

         <button
            type='button'
            id='imprint-button'
            onClick={() => {
               setLayerActive(true);
            }}
         >
            <Icon
               icon='imprint'
            />
         </button>
      </>
   );
}
