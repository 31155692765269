import React, { useEffect, useState } from 'react';

import './styles.scss';
import { getUrlParameter } from '../../../util';
import { Icon } from '../../../components';

export default function CartCounterIcon(): JSX.Element | null {
   if (getUrlParameter('cart') === 'false') {
      return null;
   }

   // we need this to reload the component the "react way"
   const [counter, setcounter] = useState<number | null | undefined>(0);
   useEffect(
      () => {
         const handleChangeCart = () => {
            const cartString = localStorage.getItem('cart');

            if (cartString) {
               setcounter(JSON.parse(cartString).length);
            } else {
               setcounter(0);
            }
         };

         try {
            handleChangeCart();

            window.addEventListener('change:cart', handleChangeCart);

         } catch (error) {
            console.log(error);
         }

         return () => {
            window.removeEventListener('change:cart', handleChangeCart);
         };
      },
      [localStorage.getItem('cart')],
   );

   return (
      <div className='cart-icon'>
         <Icon
            icon='cart'
         />
         <span>
            {counter || 0}
         </span>
      </div>
   );
}
