/* webpack-strip-block:removed */
import CartItem from './CartItem/interfaces';
const STORAGE_NAME = 'cart';

/**
 * @function getCartItems
 * @description lists items from localStorage "Cart"
 */
export function getCartItems(): Array<CartItem> {
   const storage = localStorage.getItem(STORAGE_NAME);

   if (storage && storage?.length) {
      return JSON.parse(storage);
   }

   return [];
}

/**
 * @function addCartItem
 * @description adds an item to the localStorage "Cart"
 */
export function addCartItem(item: CartItem): void {
   const storage = getCartItems();
   const oldStorage = [...storage];

   if (!storage.length) {
      localStorage.setItem(STORAGE_NAME, JSON.stringify([item]));
   } else {
      if (!containsCartItem(item.uid)) {
         storage.push(item);
         localStorage.setItem(STORAGE_NAME, JSON.stringify(storage));
      }
   }
   dispatchChangeCartEvent();

   /* webpack-strip-block:removed */
}

/**
 * @function containsCartItem
 * @param uid {(string | number)} uid of the item to look for.
 * @description checks if an item with the provided uid exists in the cart
 */
export function containsCartItem(uid: string | number): boolean {
   const storage = getCartItems();

   if (storage.length && storage.find((element) => element.uid === `${uid}`)) {
      return true;
   }

   return false;
}

/**
 * @function clearCart
 * @description removes all items from the cart
 */
export function clearCart(): void {
   /* webpack-strip-block:removed */

   localStorage.removeItem(STORAGE_NAME);
   dispatchChangeCartEvent();
}

/**
 * @function removeCartItem
 * @description removes a single cart item with the provided uid
 * @param uid uid of the cart item to be removed
 */
export function removeCartItem(uid: string | number): void {
   const storage = getCartItems();
   const oldStorage = [...storage];

   if (storage.length) {
      let itemPosition: undefined | number;

      storage.find((element, index) => {
         if (element.uid === `${uid}`) {
            itemPosition = index;

            return true;
         }

         return false;
      });

      if (itemPosition !== undefined) {
         storage.splice(itemPosition, 1);
         localStorage.setItem(STORAGE_NAME, JSON.stringify(storage));

         dispatchChangeCartEvent();
      }
   }

   /* webpack-strip-block:removed */
}

/**
 * @function downloadCart
 * @description bundles and downloads the cart.
 */
export function downloadCart(): void {
   const cartItems = getCartItems();

   if (!cartItems.length) {
      return;
   }

   let downloadURL = process.env.SHOPPING_CART_URL || process.env.SSP_URL;
   const fileHashes = [];
   const fileNames = [];

   for (const cartItem of cartItems) {
      fileHashes.push(cartItem.fileHash);
      fileNames.push(encodeURIComponent(cartItem.fileName));
   }

   downloadURL = `${downloadURL}/module/content/api/v1/depot/asset-archive?`;

   const link = `${downloadURL}files=${fileHashes.join(',')}&filenames=${fileNames.join(',')}`;
   const elementLink = document.createElement('a');
   elementLink.setAttribute('href', link);
   elementLink.setAttribute('download', '');
   elementLink.click();
   elementLink.remove();

   /* webpack-strip-block:removed */
}

/**
 * Event which will be triggered on cart change.
 * @param cart
 */
export const dispatchChangeCartEvent = (): void => {
   const changeCartEvent = new CustomEvent('change:cart');
   window.dispatchEvent(changeCartEvent);
};

export function getCartSize(items: Array<CartItem>): number {
   let size = 0;

   items?.forEach((item) => size = size + item.size);

   return size;
}
