import React, { useEffect, useRef } from 'react';
import { dispatchLogEvent } from '../../../plugins';
import LogData from '../../../plugins/ActivityLogger/interfaces';

import { Breadcrumb, PopupAsset } from '../../../util/ContentHandler/createDataObjectFromSSP/interfaces';

/**
 * @class VideoContent
 * @classdesc displays the video content inside of the popup.
 * @hideconstructor
 */
export default function VideoContent(props: PopupAsset): JSX.Element {
   const videoRef = useRef<HTMLVideoElement>(null);

   useEffect(() => {
      const video = videoRef.current;

      /* webpack-strip-block:removed */
   }, []);

   return (
      <video
         ref={videoRef}
         aria-label={props.data.name}
         autoPlay={true}
         controls
      >
         <source
            src={props.data.src}
            type={props.data.type}
         />
      </video>
   );
}
