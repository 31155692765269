import SSPContentVO from '@ssp/sspcontentext/dist/model/SSPContentVO';
import createAssets from './createAssets';
import MainMenuItem, { Breadcrumb } from './interfaces';

/**
 * @function createMainMenu
 * @description creates the main menu data tree recursively
 * @param object SSPContentVO
 * @param prevbreadcrumb {Breadcrumb[]}
 * @returns {MainMenuItem[]}
 */
export default function createMainMenu(object: SSPContentVO, prevbreadcrumb: Array<Breadcrumb> = []): Array<MainMenuItem> {
   const mainMenuItems: Array<MainMenuItem> = [];
   let children = object.asModel.reflist('contentclusters');

   if (!children.length) {
      children = object.asModel.reflist('topiccontents');
   }

   if (!children.length) {
      children = object.asModel.reflist('subcategories');
   }

   // creates the breadcrumbs and is the basis for the url handling
   const currentBreadcrumb: Array<Breadcrumb> = JSON.parse(JSON.stringify(prevbreadcrumb));

   if (object.asModel.str('name') !== '') {
      currentBreadcrumb.push({
         uid: object.uid,
         name: object.asModel.str('name').trim(),
      });
   }

   children.forEach((child) => {
      if (currentBreadcrumb.find((bc) => bc.uid === child.uid)) {
         return;
      }
      mainMenuItems.push({
         title: child.asModel.str('name').trim(),
         uid: child.uid,
         items: createMainMenu(child, currentBreadcrumb),
         assets: createAssets(child, currentBreadcrumb)?.filter((item, _i, arr) => arr.indexOf(item) !== -1),
         breadcrumb: currentBreadcrumb,
      });
   });

   return mainMenuItems;
}
