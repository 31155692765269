/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react';
import { findItemByUid, useGlobalData } from '../../../util';
import MasonryItem from '../../Content/Masonry/MasonryItem/interfaces';

import removeOldMarkers from './removeOldMarkers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function StateOnRouteChange(props: any): null {
   const { content, state } = useGlobalData();

   /* webpack-strip-block:removed */

   /* NON_SPEAKING_URLS-START */
   useEffect(() => {
      const pathUids = window.location.search.split('path=')[1]?.split('&')[0]?.split('%2F');

      if (pathUids && pathUids.indexOf(props.uid) !== -1) {
         // exact
         if (pathUids && pathUids[pathUids?.length - 1] === props.uid) {
            const title = document.querySelector('head title') as HTMLElement;
            title.innerText = props.title;

            if (props.assets) {
               props.setAssets(props.assets);
               state.set.pageStateValue({
                  ...state.value,
                  currentMenuItem: { uid: props.uid, name: props.title },
                  currentBreadcrumb: props.breadcrumb,
                  menuOpen: false,
               });
            }
         }

         // needed for directly opening assets
         if ((findItemByUid(content.mainMenu, pathUids[pathUids?.length - 1]) as unknown as MasonryItem)?.href && pathUids && pathUids[pathUids?.length - 2] === props.uid) {
            props.setAssets(props.assets);

            removeOldMarkers();

            state.set.pageStateValue({
               ...state.value,
               currentMenuItem: { uid: props.uid, name: props.title },
               currentBreadcrumb: props.breadcrumb,
               menuOpen: false,
            });
         }
      }
   }, [window.location.href]);
   /* NON_SPEAKING_URLS-END */

   useEffect(() => {
      removeOldMarkers();

      if (!props.anchor?.parentElement?.querySelector('ul')) {
         props.anchor?.parentElement?.setAttribute('deepest', '');
         props.anchor?.parentElement?.previousElementSibling?.setAttribute('pre-deepest', '');
      }
   }, [
      props.anchor,
   ]);

   return null;
}
