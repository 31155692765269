import React from 'react';
import ReactDOM from 'react-dom';
{/* webpack-strip-block:removed */ }

{/* webpack-strip-block:removed */ }
import App from './App';

import { GlobalContextProvider, SettingProvider } from './util';
import ErrorBoundary from './util/ErrorBoundary';

import { BrowserRouter as Router } from 'react-router-dom';

/**
 * @function renderWebplayer
 * @description Before we start rendering the app itself we create a global context which is supposed to handle global data for all components to use.
 */
ReactDOM.render(
   <ErrorBoundary>
      <SettingProvider
         value={{}}
      >
         <GlobalContextProvider
            value={{}}
         >
            <Router
               basename={document.baseURI.replace(window.location.origin, '') + (process.env.FALLBACK_TO_INDEX_HTML === 'false' ? 'index.html' : '')}
            >
               <App />
            </Router>
         </GlobalContextProvider>
      </SettingProvider>
   </ErrorBoundary>,
   document.getElementById('root'),
);

// we need this because react router adds a trailing / to the and of index.html
if (process.env.FALLBACK_TO_INDEX_HTML) {
   history.pushState = ((f) => function pushState(){
      arguments[2] = arguments[2].replace('index.html/', 'index.html');
      const ret = f.apply(this, arguments);

      return ret;
   })(history.pushState);
}
