import React, { useEffect, useMemo, useRef, useState } from 'react';
/* webpack-strip-block:removed */
import './styles.scss';
import CartItem from '../CartItem';
import CartItemProps from '../CartItem/interfaces';
import ClearCartButton from '../ClearCartButton';
import DownloadCartButton from '../DownloadCartButton';

import { getCartItems, getCartSize } from '../cartStorageHandler';
import getTranslation from '../../../util/ContentHandler/getTranslation';
import { getUrlParameter } from '../../../util';
import { Scrollbar } from '../../../components';

export default function CartOverlayContent(): JSX.Element | null {
   if (getUrlParameter('cart') === 'false') {
      return null;
   }

   // we need this to reload the component the "react way"
   const [cartItems, setCartItems] = useState<Array<CartItemProps>>([]);

   const scrollable = useRef(null);

   useEffect(
      () => {
         const handleChangeCart = () => {
            setCartItems(getCartItems());
         };
         handleChangeCart();

         window.addEventListener('change:cart', handleChangeCart);

         return () => {
            window.removeEventListener('change:cart', handleChangeCart);
         };
      },
      [],
   );

   /* webpack-strip-block:removed */

   return useMemo(() => (
      <>
         <h1
            id='menu-overlay-headline'
         >
            {getTranslation()?.cart.title}
            <span>{cartItems.length}</span>
         </h1>

         <div
            className='ulwrapper'
         >
            <Scrollbar>
               <ul
                  className='cart-items'
                  ref={scrollable}
               >
                  {cartItems?.map((cartItem: CartItemProps) => <CartItem key={cartItem.uid} {...cartItem} />)}
               </ul>
            </Scrollbar>
         </div>
         <div className='buttons'>
            <DownloadCartButton />
            <ClearCartButton />
         </div>
      </>
   ),
   [
      getCartItems(),
   ]);
}
