/// Text types in the imprint text list
export enum TextType {
   Headline,
   List,
   Paragraph,
}

/// Text variants in the imprint text list
export enum TextVariant {
   Ul,
   Ol,
}
