import React from 'react';

import {
   /* webpack-strip-block:removed */

   /* CART-START */
   OpenCartButton,
   /* CART-END */

   /* webpack-strip-block:removed */

   /* MULTILANG-START */
   OpenLanguageButton,
   /* MULTILANG-END */

   /* webpack-strip-block:removed */
} from '../../plugins';

import './styles.scss';
import handleLogoState from './handleLogoState';
import HeaderProps from './interfaces';
import OpenMenuButton from './OpenMenuButton';
import { Picture } from '..';

import { removeUrlParameter, useGlobalData, useWindowSize } from '../../util';
import { NavLink } from 'react-router-dom';

/**
 * @class Header
 * @classdesc displays the header with its buttons.
 * @hideconstructor
 */
export default function Header(props: HeaderProps): JSX.Element {
   const { width } = useWindowSize();
   const { state } = useGlobalData();

   const handleLogoClick = () => {
      handleLogoState(state);

      /* webpack-strip-block:removed */
   };

   // mobile header
   if (width <= 960) {
      return (
         <header
            id='header'
         >
            <OpenMenuButton />
            <NavLink
               to={{
                  pathname: '/',
                  search: removeUrlParameter(window.location.search, 'path'),
               }}
               onClick={handleLogoClick}
            >
               <h1
                  aria-label={props.logo.title || 'Siemens'}
               >
                  <Picture {...props.logo} />
               </h1>
            </NavLink>
            <div className='buttons'>
               {/* CART-START */}
               <OpenCartButton />
               {/* CART-END */}

               {/* webpack-strip-block:removed */}

               {/* MULTILANG-START */}
               <OpenLanguageButton />
               {/* MULTILANG-END */}

               {/* webpack-strip-block:removed */}
            </div>
         </header>
      );
   }

   // desktop header
   return (
      <header
         id='header'
      >
         <NavLink
            to={{
               pathname: '/',
               search: removeUrlParameter(window.location.search, 'path'),
            }}
            onClick={handleLogoClick}
         >
            <h1
               aria-label={props.logo.title || 'Siemens'}
            >
               <Picture {...props.logo} />
            </h1>
         </NavLink>
         <div className='buttons'>
            {/* CART-START */}
            <OpenCartButton />
            {/* CART-END */}

            {/* webpack-strip-block:removed */}

            {/* MULTILANG-START */}
            <OpenLanguageButton />
            {/* MULTILANG-END */}

            {/* webpack-strip-block:removed */}
         </div>
      </header>
   );
}
