/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react';

import { PopupAsset } from '../../../../util/ContentHandler/createDataObjectFromSSP/interfaces';
import { useGlobalData } from '../../../../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function StateOnRouteChange(props: PopupAsset): null {
   const { content, state } = useGlobalData();

   /* webpack-strip-block:removed */

   /* NON_SPEAKING_URLS-START */
   useEffect(() => {
      const pathUids = new URLSearchParams(window.location.search).get('path')?.split('/');

      if (pathUids && pathUids.indexOf(props.uid) !== -1) {
         // exact
         if (pathUids && pathUids[pathUids?.length - 1] === props.uid) {
            const title = document.querySelector('head title') as HTMLElement;
            title.innerText = props.title;

            state.set.content({
               ...content,
               popup: props,
            });
         }
      }

      return () => {
         state.set.content({
            ...content,
            popup: undefined,
         });
      };
   }, [window.location.search]);
   /* NON_SPEAKING_URLS-END */

   return null;
}
