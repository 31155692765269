import { SSPScalemode } from '@ssp/sspcontentext';
import SSPContentVO from '@ssp/sspcontentext/dist/model/SSPContentVO';
import { getURLByTitleAndBreadcrumb } from '../../../../../util';
import { Asset } from './interfaces';

/**
 * @function createAssets
 * @description creates the assets of an main menu item
 * @param model SSPContentVO
 * @param currentBreadcrumb {any}
 * @returns {(Asset[] | undefined)}
 */
export default function createAssets(model: SSPContentVO, currentBreadcrumb: any): Array<Asset> | undefined {
   const sspAssets = model.asModel.reflist('assets');

   if (!sspAssets.length) {
      return undefined;
   }
   const assets: Array<Asset> = [];

   sspAssets.forEach((sspAsset) => {
      const data = {};

      switch (sspAsset.asAsset.fileFamily) {
         case 'image':
            data.alt = sspAsset.asAsset.metadata.str('name') || '';
            data.sources = [
               {
                  srcset: sspAsset.asAsset.thumbUrl(1920, 1080, SSPScalemode.CROP),
                  media: '(min-width: 1201px)',
               },
               {
                  srcset: sspAsset.asAsset.thumbUrl(1200, 675, SSPScalemode.CROP),
                  media: '(min-width: 601px)',
               },
               {
                  srcset: sspAsset.asAsset.thumbUrl(600, 338, SSPScalemode.CROP),
                  media: '(min-width: 0)',
               },
            ];

            break;

         case 'video':
            data.type = sspAsset.asAsset.fileProperties.str('fileType');
            data.name = sspAsset.asAsset.metadata.str('name');
            data.src = sspAsset.asAsset.url;
            break;

         case 'unknown':
            data.headline = sspAsset.asAsset.metadata.str('name');
            data.url = sspAsset.asAsset.url;
            break;

         case 'document':
            data.url = sspAsset.asAsset.url;

            break;
         default:
            console.warn(`Asset type "${sspAsset.asAsset.fileFamily}" not defined`);
            break;
      }

      const breadcrumb = JSON.parse(JSON.stringify(currentBreadcrumb));
      breadcrumb.push({
         uid: model.uid,
         name: model.asModel.str('name').trim(),
      });

      assets.push({
         title: sspAsset.asAsset.metadata.str('name') || sspAsset.name,
         type: sspAsset.asAsset.fileFamily,
         subheadline: sspAsset.asAsset.metadata.str('subtitle'),
         preheadline: sspAsset.asAsset.metadata.str('title'),
         link: sspAsset.asAsset.url,
         /* webpack-strip-block:removed */
         /* NON_SPEAKING_URLS-START */
         href: `?path=${getURLByTitleAndBreadcrumb(sspAsset.uid, breadcrumb).replace('/', '').replace(/\//g, '%2F')}`,
         /* NON_SPEAKING_URLS-END */
         uid: sspAsset.uid,
         image: sspAsset.asAsset.thumbUrl(500, 400, SSPScalemode.CROP),
         fileHash: sspAsset.asAsset.fileProperties.fileHash,
         fileName: sspAsset.asAsset.fileProperties.originalFilename,
         size: sspAsset.asAsset.fileProperties.size,
         data,
      } as Asset);
   });

   return assets;
}
