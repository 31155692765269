import React, { useEffect, useRef, useState } from 'react';
import DocumentProps from '../../../../components/Popup/DocumentContent/interfaces';
import { LANGUAGEKEY } from '../../../../enums';
import PDFViewer from '@ssp/pdfviewer';
import { useSettings } from '../../../../util';

import './styles.scss';

import '../../../../../node_modules/@ssp/pdfviewer/style.css';

/**
 * @namespace DocumentContent
 * @class SB12
 * @classdesc displays the document content inside of the popup. This is an implementation of [SB12 PDF Viewer]{@link https://cgit.sb12.de/typescript/pdfviewer.git}
 * @hideconstructor
 */
export default function SB12(props: DocumentProps): JSX.Element {
   const { language } = useSettings();
   const ref = useRef<HTMLDivElement>(null);
   const [pDFViewer, setPDFViewer] = useState<PDFViewer>()

   useEffect(() => {
      try {
         if (props.url && !pDFViewer) {
            const pdfviewer = new PDFViewer({
               pdf: props.url,
               language: LANGUAGEKEY[language.value],
               parent: '#pdfviewer',
               controls: {
                  print: false,
                  download: false,
               },
            });
            pdfviewer.init()
            setPDFViewer(pdfviewer);
         }
      } catch (error) {
         console.error(error);
      }
   });

   useEffect(() => {
      if (ref.current) {
         new ResizeObserver(() => {
            if (!ref.current || !ref.current.parentElement) {
               return;
            }
            const plus = document.querySelector('.pdfviewer-controlcontainer--plus button') as HTMLButtonElement;

            if (ref.current.clientWidth === ref.current.parentElement?.clientWidth) {
               plus?.setAttribute('disabled', 'disabled');
            } else {
               plus?.removeAttribute('disabled');
            }
         }).observe(ref.current);
      }
   }, []);

   return (
      <div
         id='pdfviewer'
         ref={ref}
      />
   );
}
