/**
 * @function getParameterByName
 * @description finds a provided url parameter in an optionally given url
 * @param name {string} name of the url parameter
 * @param url {string=} url to get the parameter from. Default si the current url.
 * @returns string value of the requestet parameter or null if not found.
 */
export default function getParameterByName(name: string, url: string | undefined = window.location.href): string | null {
   name = name.replace(/[[\]]/g, '\\$&');
   const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
   const results = regex.exec(url);

   if (!results) {
      return null;
   }
   if (!results[2]) {
      return '';
   }

   return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
