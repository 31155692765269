import React, { ReactNode } from 'react';
import { Icon } from '../../../../components';
import { LayerProps } from './interfaces';

import './styles.scss';

/**
 * A layer
 * @param props (see ./interfaces.ts for details)
 */
export default function Layer(props: LayerProps) {
   let inner: ReactNode | string | {} = ''; // Content of the layer

   if (typeof props.children === 'string') {
      inner = props.children
         .replace(/&shy;/g, '\u00AD')
      ;
   } else if (Array.isArray(props.children)) {
      inner = <>
         {props.children.map((child: ReactNode | string) => {
            if (typeof child === 'string') {
               child = child
                  .replace(/&shy;/g, '\u00AD')
               ;
            }

            return child;
         })}
      </>;
   }

   return (
      <>
         <aside
            className={`layer ${props.active ? 'layer--active' : ''} ${props.classes ? props.classes : ''}`}
            onClick={(event: React.MouseEvent) => {
               if (!(event.target as HTMLElement).closest('.layer-content')) {
                  props.closeFn();
               }
            }}
         >
            <div
               className='layer-content'
            >
               {inner}
            </div>
            <button
               type='button'
               className='layer-close'
               aria-label='close'
               onClick={() => props.closeFn()}
            >
               <Icon
                  icon='close'
               />
            </button>
         </aside>
      </>
   );
}
