import React from 'react';

import './styles.scss';
import { PopupAsset } from '../../../util/ContentHandler/createDataObjectFromSSP/interfaces';

/* webpack-strip-block:removed */

/* PDFSB12-START */
import SB12 from '../../../plugins/PDF/Studio-B12';
/* PDFSB12-END */

/**
 * @namespace DocumentContent
 * @class DocumentContent
 * @classdesc displays the document content inside of the popup.
 * @hideconstructor
 */
export default function DocumentContent(props: PopupAsset): JSX.Element {
   /* webpack-strip-block:removed */

   /* PDFSB12-START */
   if (process.env.PLUGINS?.indexOf('pdf.sb12') !== -1) {
      return <SB12 {...props.data} />;
   }
   /* PDFSB12-END */

   return (
      <iframe
         src={props.data.url}
         style={{
            width: '100%',
            height: '100%',
         }}
      >
         This browser does not support PDFs. Please download the PDF to view it: Download PDF
      </iframe>
   );
}
