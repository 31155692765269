import React from 'react';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import './styles.scss';
import ScrollableProps from './interfaces';

export default function Scrollbar(props: ScrollableProps): JSX.Element {
   return (
      <SimpleBar>
         {props.children}
      </SimpleBar>
   );
}
