import React, { useEffect, useRef } from 'react';

import './styles.scss';
import MainMenuItem from '../../util/ContentHandler/createDataObjectFromSSP/interfaces';
import MenuItem from './MenuItem';
import MenuProps from './interfaces';
import { Scrollbar } from '..';

import { elementIsVisible, useGlobalData } from '../../util';
import { getUrlParameter } from '../../util';

/**
 * @class Menu
 * @classdesc displays the menu beside the content area. It's the start of the recursive rendering of the menu items.
 * @hideconstructor
 */
export default function Menu(props: MenuProps): JSX.Element | null {
   const { content, state } = useGlobalData();
   const scrollable = useRef<HTMLUListElement>(null);

   useEffect(
      () => {
         if (state.value.menuOpen) {
            document.getElementById('root')?.setAttribute('menu-open', '');
         } else {
            document.getElementById('root')?.removeAttribute('menu-open');
         }
      },
      [state.value.menuOpen],
   );

   useEffect(() => {
      const mainMenu = document.querySelector('#main-menu > ul');
      let timer: NodeJS.Timeout;

      mainMenu?.addEventListener('scroll', () => {
         if(timer !== null) {
            clearTimeout(timer);
         }
         timer = setTimeout(() => {
            const visibleMenuItems: Array<HTMLElement> = [];

            mainMenu.querySelectorAll('a').forEach((element) => {
               if (elementIsVisible(element as HTMLElement)) {
                  visibleMenuItems.push(element as HTMLElement);
               }
            });
            (mainMenu.querySelector('[lowest-visible]') as HTMLElement)?.removeAttribute('lowest-visible');
            visibleMenuItems[visibleMenuItems.length - 1]?.setAttribute('lowest-visible', '');
         }, 50);
      });
   }, [scrollable.current]);

   if (!content.mainMenu?.length) {
      return null;
   }

   return (
      <nav
         id='main-menu'
         aria-label='main menu'
         aria-hidden={getUrlParameter('menu') === 'false' ? 'true' : 'false'}
      >
         <Scrollbar>
            <ul
               className='main-menu-main-ul'
               ref={scrollable}
            >
               {content.mainMenu?.map((item: MainMenuItem, index: number) => (
                  <li
                     key={index}
                     className='has-items'
                     data-closed={true}
                  >
                     <MenuItem
                        {...item}
                        setAssets={props.setAssets}
                     />
                  </li>
               ))}
            </ul>
         </Scrollbar>
      </nav>
   );
}
