import SSPContentExt, { SSPScalemode } from '@ssp/sspcontentext';
import createMainMenu from './createMainMenu';

/**
 * @function createGlobalDataObjectFromSSP
 * @param ssp is the ssp content ext object
 * @description creates the global data object for the whole app
 * @returns {Promise<void>}
 */
export default async function createGlobalDataObjectFromSSP(ssp: {
   ext: SSPContentExt;
   init: () => Promise<void>;
}): Promise<any> {
   return new Promise(async (resolve, reject) => {
      if (!ssp.ext) {
         reject('no ssp');
      }
      const contactModel = ssp.ext.startModel.asModel.ref('mainContact');
      const contactObject = {};

      if (contactModel.exists) {
         contactObject.contact_name = contactModel.asModel.str('name');
         contactObject.contact_description = contactModel.asModel.str('description');
         contactObject.company_adress = contactModel.asModel.str('companyAdress');
         contactObject.company_name = contactModel.asModel.str('companyName');
         contactObject.telephone = contactModel.asModel.str('telephone');
         contactObject.email = contactModel.asModel.str('email');
         contactObject.contact_image_src = contactModel.asModel.ref('image').asAsset.thumbUrl(500, 500);
      }

      const globalDataObject = {
         home: {
            claim: ssp.ext.startModel.asModel.str('claim'),
            background: {
               large: ssp.ext.startModel.asModel.ref('background').asAsset.thumbUrl(1920, 540, SSPScalemode.CROP),
               small: ssp.ext.startModel.asModel.ref('background').asAsset.thumbUrl(960, 270, SSPScalemode.CROP),
            },
         },
         mainMenu: createMainMenu(ssp.ext.startModel),
      };

      resolve(globalDataObject);
   });
}
