export enum Setting {
   Language,
}

/// Possible languages of the application
export enum Language {
   /* GERMAN-START */
   De, // german
   /* GERMAN-END */
   /* ENGLISH-START */
   En, // english
   /* ENGLISH-END */
   /* webpack-strip-block:removed */
}

export enum LANGUAGEKEY {
   /* GERMAN-START */
   'de-DE',
   /* GERMAN-END */
   /* ENGLISH-START */
   'en-US',
   /* ENGLISH-END */
   /* webpack-strip-block:removed */
}

export enum LanguageName {
   /* GERMAN-START */
   'Deutsch',
   /* GERMAN-END */
   /* ENGLISH-START */
   'English',
   /* ENGLISH-END */
   /* webpack-strip-block:removed */
}
