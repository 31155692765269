// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/noto-sans-jp-v28-latin-700.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/noto-sans-jp-v28-latin-500.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/noto-sans-jp-v28-latin-300.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hatebur Bold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")}@font-face{font-family:\"Hatebur Regular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")}@font-face{font-family:\"Hatebur Light\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\")}", "",{"version":3,"sources":["webpack://../_fonts.scss"],"names":[],"mappings":"AAAA,WACG,0BAAA,CACA,2DAAA,CAGH,WACG,6BAAA,CACA,2DAAA,CAGH,WACG,2BAAA,CACA,2DAAA","sourcesContent":["@font-face {\n   font-family: 'Hatebur Bold';\n   src: url('../assets/fonts/noto-sans-jp-v28-latin-700.woff2') format('woff2');\n}\n\n@font-face {\n   font-family: 'Hatebur Regular';\n   src: url('../assets/fonts/noto-sans-jp-v28-latin-500.woff2') format('woff2');\n}\n\n@font-face {\n   font-family: 'Hatebur Light';\n   src: url('../assets/fonts/noto-sans-jp-v28-latin-300.woff2') format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
