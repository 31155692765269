import { State } from '../../util/ContentHandler/interfaces';

import removeOldMarker from '../Menu/MenuItem/removeOldMarkers';

export default function handleLogoState(state: State): void {
   removeOldMarker();
   if (state?.set?.pageStateValue) {
      state.set.pageStateValue({
         ...state.value,
         currentMenuItem: {
            name: 'home',
            uid: '',
         },
         menuOpen: false,
      });
   }
}
