import React, {
   ProviderProps,
   useCallback,
   useContext,
   useEffect,
   useState,
} from 'react';

import SSPContentExt from '@ssp/sspcontentext';

import ContentHandler from '../ContentHandler';
import DEFAULT_CONTEXT from '../ContentHandler/constants';
import { getUrlParameter } from '..';

import DEFAULT_CONTEXT_TYPE, { GlobalDataObject } from '../ContentHandler/interfaces';
import { Language, LANGUAGEKEY } from '../../enums';

import { PageStateValue } from './interfaces';
import { useSettings } from '../SettingContextProvider';
// initialize global context - yes it's kinda redundant
export const GLOBAL_CONTEXT: React.Context<DEFAULT_CONTEXT_TYPE> = React.createContext(DEFAULT_CONTEXT);

/**
 * @class GlobalContextProvider
 */
export default function GlobalContextProvider(
   props?: ProviderProps<Record<string, unknown>>,
): JSX.Element {
   const settings = useSettings();

   const [staticData, setStaticData] = useState<GlobalDataObject | Record<string, unknown>>({});
   const defaultState = {
      menuOpen: false,
      currentMenuItem: {
         name: '',
         uid: '',
      },
      currentBreadcrumb: [],
      cartItems: [],
   };
   const [pageState, setPageState] = useState<PageStateValue>(defaultState);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [ssp, setSSP] = useState<{
      ext: SSPContentExt;
      init: () => Promise<void>;
   }>();

   const initSSP = async (lang: string) => {
      try {
         /**
          * create class that handles the SSPContentExt
          */
         const ext = SSPContentExt.instance;

         const sspWeb = {
            ext,
            init: async () => {
               try {
                  if (!process.env.SSP_URL) {
                     throw new Error('no SSP env variable defined');
                  }

                  /**
                   * this is the endpoint the data is received from
                   */
                  ext.configure(process.env.SSP_URL);

                  /**
                   * this way, you set the language your data is returned for.
                   * You can call setCurrentLanguage("de-DE") whenever you want, subsequent requests to the data will return
                   * the new language if available
                   */
                  ext.setCurrentLanguage(lang);

                  /**
                   * initialization of data is done based on a (virtual) device id
                   */
                  const device = getUrlParameter('device');

                  if (device) {
                     await ext.initWithDevice(device);
                  } else if (process.env.DEFAULT_DEVICE) {
                     await ext.initWithDevice(process.env.DEFAULT_DEVICE);
                  }
               } catch (error) {
                  console.error(`Couldn't initialize SSP. Error: ${error}`);
               }
            },
         };

         await sspWeb.init();

         return sspWeb;
      } catch (error) {
         throw new Error(error);
      }
   };

   /**
    * retrieves the pages content from the indexeddb exposes and if not found from ../Router/map.ts or the ssp.
    */
   const handleInitialPageLoad = useCallback(
      async () => {
         try {
            const trySSP = async () => await initSSP(LANGUAGEKEY[settings.language.value]);

            let sspExt = await trySSP();

            if (sspExt) {
               const content = await ContentHandler(sspExt);
               setStaticData(content);
               setSSP(sspExt);

               if (window.location.pathname.replace('index.html', '') === process.env.BASE_URL && !getUrlParameter('path')) {
                  setState({
                     ...pageState,
                     currentMenuItem: {
                        name: 'home',
                        uid: '',
                     },
                  });
               }
            } else {
               const retrySSPConnectionInterval = setInterval(
                  async () => {
                     sspExt = await trySSP();

                     if (!sspExt) {
                        clearInterval(retrySSPConnectionInterval);
                     } else {
                        setSSP(sspExt);
                     }
                  },
                  1000,
               );
            }

            window.addEventListener('change:language', (e: CustomEventInit) => {
               if (sspExt) {
                  sspExt.ext.setCurrentLanguage(e.detail.language);
                  (async () => {
                     const content = await ContentHandler(sspExt);
                     setStaticData(content);
                  })();
               }
            });
         } catch (error) {
            console.error(error);
         }
      },
      [],
   );

   useEffect(() => {
      handleInitialPageLoad();
   }, []);

   /**
    * Adds to the global setting state. Duplicates will be overwritten
    * @param state an object which contains globally used settings.
    */
   const setState = (
      state: PageStateValue,
   ) => {
      setPageState(((currentState) => ({ ...currentState, ...state })));
   };

   /**
    *  Adds to the global content object. Duplicates will be overwritten.
    * @param content an object which contains globally used content.
    */
   const setContent = (content: GlobalDataObject) => {
      setStaticData({ ...staticData, ...content });
   };

   // rendering
   return (
      <GLOBAL_CONTEXT.Provider
         value={{
            content: staticData,
            state: {
               value: pageState,
               set: {
                  pageStateValue: setState,
                  content: setContent,
               },
            },
         } as never}
      >
         {props?.children}
      </GLOBAL_CONTEXT.Provider>
   );
}

/**
 * @function useGlobalData
 * @description custom hook for using the global data context.
 */
export const useGlobalData = (): DEFAULT_CONTEXT_TYPE => useContext<DEFAULT_CONTEXT_TYPE>(GLOBAL_CONTEXT);

/**
 * @function dispatchChangeSSPLanguageEvent
 * @description Event which can be called to trigger a language change of the ssp content ext.
 * @param language
 */
export const dispatchChangeSSPLanguageEvent = (
   language: Language,
): void => {
   const languageChangeEvent = new CustomEvent('change:language', {
      detail: {
         language: LANGUAGEKEY[language],
      },
   });
   window.dispatchEvent(languageChangeEvent);
};
