import React, { useEffect, useState } from 'react';

import './styles.scss';
import PictureProps from './interfaces';

/** @typedef PictureProps
 * @property sources {(Source[])=} list of source tag data
 * @property src {string=} src of a single image
 * @property alt {string} alt text of the image
 * @property title {string=} title text of the image
 * @property credits {string=} text which will be used as hint of the images origin
 * @property height {string=} height in px of the image
 * @property width {string=} width in px of the image
 */

/** @typedef Source
 * @property media {string} css media query
 * @property srcset {string} image url
 * @property credits {string=} text which will be used as hint of the images origin
 */

/**
 * @class Picture
 * @classdesc displays a Picture or image tag depending on input.
 * @property props {PictureProps} properties of the picture component.
 */
export default function Picture(props: PictureProps): JSX.Element {
   const [image, setImage] = useState<JSX.Element>();

   useEffect(
      () => {
         if (props.sources && props.sources.length > 0) {
            setImage(
               <picture>
                  {props.sources?.map((source, index) => (
                     <source
                        key={index}
                        media={source.media}
                        srcSet={source.srcset}
                     />
                  ))}
                  <img
                     src={props.src || props.sources[props.sources.length - 1].srcset}
                     alt={props.alt}
                     title={props.title}
                     height={props.height}
                     width={props.width}
                  />
               </picture>,
            );
         }
      },
      [
         props.sources,
      ],
   );

   if (!props.credits && !props.sources?.length) {
      return (
         <img
            src={props.src}
            alt={props.alt}
            title={props.title}
            height={props.height}
            width={props.width}
         />
      );
   }

   return (
      <div className='picture'>
         {image ||
            <img
               src={props.src}
               alt={props.alt}
               title={props.title}
               height={props.height}
               width={props.width}
            />
         }
         {props.credits &&
            <small className='picture--credits'>{props.credits}</small>
         }
      </div>
   );
}
