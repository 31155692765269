// TODO: should be refactored to not include optional translation by default

export default {
   /* GERMAN-START */
   'de': {
      assetType: {
         website: 'Webseite',
         unknown: 'Webseite',
         image: 'Bild',
         video: 'Video',
         document: 'PDF',
         brochure: 'Broschüre',
         presentation: 'Präsentation',
      },
      content: {
         no_assets: 'Keine Inhalte vorhanden',
      },
      startpage: {
         further_links: 'Weiterführerende Links',
         help: 'Hilfe',
         imprint: 'Impressum',
         text: 'Hier steht Ihnen umfangreiches Informations-Material zu allen Themengebieten zur Verfügung. Wählen Sie dazu ein Thema über die Navigationsleiste auf der linken Seite aus.',

      },
      header: {
         contact: 'Kontakt',
         language: 'Deutsch',
         cart: 'Warenkorb',
         menu: 'Hauptmenü',
         search: 'Suche',
      },
      cart: {
         title: 'Warenkorb',
         download_files: 'Dateien herunterladen',
         clear_list: 'Liste leeren',
         empty_cart: 'Der Warenkorb ist bereits leer.',
         fileSize: 'Dateigröße',
      },
      contact: {
         title: 'Ich freue mich auf Ihre Kontaktaufnahme.',
         personal: 'Ihr persönlicher Ansprechpartner:',
      },
      language: {
         title: 'Sprache wechseln',
      },
      search: {
         title: 'Suche',
         results: 'Suchergebnisse:',
         placeholder: 'Suche...',
      },
      sidebar: {
         showMenuItems: 'Unterpunkte anzeigen / ausblenden',
      },
      lightbox: {
         previousSlide: 'Vorherige Präsentation',
         nextSlide: 'Nächste Präsentation',
         backToCross: 'Zurück zur Kreuzübersicht',
         message_before: 'Klicken Sie auf den folgenden Button, um den Link',
         message_after: 'in einem neuen Tab zu öffnen.',
         openNewTab: 'Webseite in neuem Tab öffnen',
         addToCart: 'Zum Warenkorb hinzufügen',
      },
      misc: {
         close: 'Schließen',
         home: 'Startseite',
      },
   } as Translation,
   /* GERMAN-END */

   /* ENGLISH-START */
   'en': {
      assetType: {
         website: 'Website',
         unknown: 'Website',
         image: 'Image',
         video: 'Video',
         document: 'PDF',
         brochure: 'Brochure',
         presentation: 'Presentation',
      },
      default: true,
      modeltypes: {
         Application: 'Application',
         Image: 'Image',
         Video: 'Video',
         Brochure: 'Brochure',
         Presentation: 'Presentation',
         Website: 'Website',
      },
      content: {
         no_assets: 'No contents available',
      },
      startpage: {
         further_links: 'Further links',
         help: 'Help',
         imprint: 'Imprint',
         text: 'Here you can find extensive information material on all topics.</br>Please select a topic using the navigation bar on the left side.',
      },
      header: {
         contact: 'Contact',
         language: 'English',
         cart: 'Cart',
         menu: 'Main menu',
         search: 'Search',
      },
      cart: {
         title: 'Cart',
         download_files: 'Download files',
         clear_list: 'Clear list',
         empty_cart: 'Your cart is already cleared.',
         fileSize: 'File size',
      },
      contact: {
         title: 'Contact support',
         personal: 'Your personal contact person:',
      },
      language: {
         title: 'Change language',
      },
      search: {
         title: 'Search',
         results: 'Search Results:',
         placeholder: 'Search...',
      },
      sidebar: {
         showMenuItems: 'Show / hide menu items',
      },
      lightbox: {
         previousSlide: 'Previous presentation',
         nextSlide: 'Next presentation',
         backToCross: 'Back to the cross overview',
         message_before: 'Click the following button to open the link',
         message_after: 'in a new browser tab.',
         openNewTab: 'Open link in new browser tab',
         addToCart: 'Add to cart',
      },
      misc: {
         close: 'Close',
         home: 'Home',
      },
   } as Translation,
   /* ENGLISH-END */

   /* webpack-strip-block:removed */
};

export interface Translation {
   assetType: {
      website: string;
      unknown: string;
      image: string;
      video: string;
      document: string;
      brochure: string;
      presentation: string;
   };
   content: {
      no_assets: string;
   };
   startpage: {
      further_links: string;
      help: string;
      imprint: string;
      text: string;

   };
   header: {
      contact: string;
      language: string;
      cart: string;
      menu: string;
      search: string;
   };
   cart: {
      title: string;
      download_files: string;
      clear_list: string;
      empty_cart: string;
      fileSize: string;
   };
   contact: {
      title: string;
      personal: string;
   };
   language: {
      title: string;
   };
   search: {
      title: string;
      results: string;
      placeholder: string;
   };
   sidebar: {
      showMenuItems: string;
   };
   lightbox: {
      previousSlide: string;
      nextSlide: string;
      backToCross: string;
      message_before: string;
      message_after: string;
      openNewTab: string;
      addToCart: string;
   };
   misc: {
      close: string;
      home: string;
   };
}
