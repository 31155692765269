import React from 'react';

import './styles.scss';
import MasonryItemProps from './interfaces';

import { AddToCartButton } from '../../../../plugins';
/* webpack-strip-block:removed */
import { getTranslation, removeUrlParameter } from '../../../../util';
import { Picture } from '../../../../components';

import { NavLink, Route, useLocation } from 'react-router-dom';
import { Asset } from '../../../../util/ContentHandler/createDataObjectFromSSP/interfaces';
import StateOnRouteChange from '../../../../components/Content/Masonry/MasonryItem/StateOnRouteChange';

/**
 * @class MasonryItem
 * @classdesc displays a single masonry item in the content area.
 * @hideconstructor
 */
export default function MasonryItem(props: MasonryItemProps): JSX.Element {
   const location = useLocation();

   /* webpack-strip-block:removed */

   /* NON_SPEAKING_URLS-START */
   const url = {
      pathname: `${props.href}${removeUrlParameter(window.location.search, 'path').replace(/\?/g, '&')}`,
   };
   /* NON_SPEAKING_URLS-END */

   const handleLinkClick = () => {
      /* webpack-strip-block:removed */
   };

   return (
      <>
         {/* webpack-strip-block:removed */}
            <StateOnRouteChange
               {...props as unknown as Asset}
            />
            {/* webpack-strip-block:removed */}
         <NavLink
            to={url}
            onClick={handleLinkClick}
         >
            <article
               className='masonry-item'
            >
               <Picture
                  src={props.image}
                  alt={props.title}
               />
               <div>
                  <div>{props.preheadline}</div>
                  <h2>{props.subheadline}</h2>
                  <span>{getTranslation().assetType[props.type] || props.type}</span>
                  {/* CART-START */}
                  <AddToCartButton
                     uid={props.uid}
                     title={props.title}
                     type={props.type}
                     size={props.size}
                     fileHash={props.fileHash}
                     fileName={props.fileName}
                  />
                  {/* CART-END */}
               </div>
            </article>
         </NavLink>
      </>
   );
}
