import React from 'react';

import IconProps from './interfaces';

/**
 * @class Icon
 * @classdesc displays a single icon.
 * @property aria-label {string=}
 * @property classes {string=}
 * @property icon {string} name of an icon file
 */
export default function Icon(props: IconProps): JSX.Element {
   return (
      <svg
         aria-hidden={props['aria-label'] === undefined}
         aria-label={props['aria-label']}
         className={props.classes}
         id={props.id}
      >
         <use
            href={`spritemap.svg#sprite-${props.icon}`}
         ></use>
      </svg>
   );
}
