import React from 'react';

import './styles.scss';
import { clearCart } from '../cartStorageHandler';
import { getTranslation, useGlobalData } from '../../../util';

export default function ClearCartButton(): JSX.Element {
   const {state} = useGlobalData();
   const handleCartClearing = () => {
      clearCart();
      state.set.pageStateValue({
         ...state.value,
         cartItems: [],
      });
   };

   return (
      <button
         type='button'
         id='clear-cart-button'
         onClick={handleCartClearing}
      >
         {getTranslation()?.cart.clear_list}
      </button>
   );
}
