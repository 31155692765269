import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Imprint from './components/Imprint';

import './scss/_fonts.scss';
import './scss/_reset.scss';
import './scss/additional.scss';

import {
   withRouter,
} from 'react-router-dom';

import { MainMenuItem } from '../../components/Menu/interfaces';
import MasonryItem from '../../components/Content/Masonry/MasonryItem/interfaces';

import { Content, Header, MenuOverlay, Popup } from '../../components';
import { findItemByUid, getURLByTitleAndBreadcrumb, getUrlParameter, makeURLFriendly, removeUrlParameter, useGlobalData } from '../../util';
import AppInfo from './components/AppInfo';
import { useEventListener } from '../../util/eventListener';

/**
 * @class App
 * @classdesc Main component which is the base of the whole application. It's used like a template.
 * @hideconstructor
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const App = withRouter(({ history, location }): JSX.Element => {
   const { content } = useGlobalData();
   const [appInfoVisible, setAppInfoVisible] = useState(false);

   useEventListener(
      'keydown',
      (event: KeyboardEvent) => {
         if (event.ctrlKey && event.altKey && event.shiftKey && event.key === 'V') {
            setAppInfoVisible(!appInfoVisible);
         }
      },
      window,
   );

   useEventListener(
      'keydown',
      (event: KeyboardEvent) => {
         if (event.key === 'Escape' && appInfoVisible) {
            setAppInfoVisible(false);
         }
      },
      window,
   );

   try {
      useEffect(
         () => {
            /**
             * @method App.handleKeypress
             * @property e {KeyboardEvent} https://developer.mozilla.org/de/docs/Web/API/KeyboardEvent
             * @description for accessibility and design reasons we show the focus ring only on press of the Tab key.
             */
            const handleKeypress = (e: KeyboardEvent) => {
               if (e.code === 'Tab') {
                  document.getElementById('root')?.setAttribute('focusable', '');
                  window.removeEventListener('keypress', handleKeypress);
               }
            };

            window.addEventListener('keydown', handleKeypress);
         },
         [],
      );

      useEffect(() => {
         // handle path url parameter
         const path = window.location.search.split('path=')[1]?.split('&')[0]?.split('%2F');

         const uidParam = getUrlParameter('uid');

         /* webpack-strip-block:removed */

         /* NON_SPEAKING_URLS-START */
         if (uidParam && content.mainMenu) {
            const item = findItemByUid(content.mainMenu, uidParam) as unknown as MasonryItem || null;

            if (item) {
               const url = (item as MasonryItem).href || `path=${getURLByTitleAndBreadcrumb(item.uid, (item as unknown as MainMenuItem).breadcrumb)}`;
               let search = removeUrlParameter(location.search, 'uid');

               search = (search.length ? `${url.replace('/', '').replace(/\//g, '%2F')}${search.replace(/\?/g, '&')}`: `${url.replace(/\//g, '').replace(/\//, '%2F')}`);

               history.replace({
                  ...location,
                  search,
               });
            }
         }
         /* NON_SPEAKING_URLS-END */
      }, [content.mainMenu]);

      const baseURL = window.location.origin;

      /**
       * @method App.return
       * @description returns the basic template and provides some properties to its components.
       */
      return (
         <>
            <Helmet>
               <base href={baseURL} />
            </Helmet>
            <Header
               logo={{
                  src: 'logo.svg',
                  alt: 'Logo',
               }}
            />
            <Content />
            <Imprint />
            <MenuOverlay />
            <Popup />
            <AppInfo
               visible={appInfoVisible}
               closeFn={() => setAppInfoVisible(false)}
            />
         </>
      );
   } catch (error) {
      console.error(error);

      return <>Something went wrong, please reload or try again later.</>;
   }
});

export default App;
