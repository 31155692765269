import React from 'react';

import { Icon } from '../..';

import { getTranslation, useGlobalData } from '../../../util';

/**
 * @class OpenMenuButton
 * @classdesc displays the button which opens the menu.
 * @hideconstructor
 */
export default function OpenMenuButton(): JSX.Element {
   const { state } = useGlobalData();

   return (
      <button
         className='open-menu-button header-button'
         type='button'
         aria-pressed={state.value.menuOpen}
         aria-label={getTranslation()?.header.menu}
         onClick={() => state.set.pageStateValue({ ...state.value, menuOpen: !state.value.menuOpen })}
      >
         <Icon
            icon='menu'
         />
      </button>
   );
}
