import React from 'react';
import VideoJS from '../../../plugins/Video/VideoJS';
import { PopupAsset } from '../../../util/ContentHandler/createDataObjectFromSSP/interfaces';
import Default from './Default';

export default function Video(props: PopupAsset): JSX.Element {
   /* webpack-strip-block:removed */

   return <Default {...props} />;
}
