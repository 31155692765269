import React from 'react';
import { beautifyBytes } from '../../../../util';
import CartItemProps from './interfaces';
import { Icon } from '../../../../components';
import './styles.scss';
import { removeCartItem } from '../../../../plugins/Cart/cartStorageHandler';

export default function CartItem(props: CartItemProps) {
   return (
      <li
         key={props.uid}
         className='cart-item'
      >
         <div
            className='data'
         >
            {props.type} | {beautifyBytes(props.size)}
         </div>
         <div className='title'>
            {props.title}

            <button
               type='button'
               onClick={() => removeCartItem(props.uid)}
            >
               <Icon
                  icon='delete'
               />
            </button>
         </div>
      </li>
   );
}
