import React, { useEffect } from 'react';

import './styles.scss';
import { Icon } from '..';
import { useGlobalData } from '../../util';
/* webpack-strip-block:removed */

/**
 * @class MenuOverlay
 * @classdesc displays the overlay which open if a click on a menu button, other than the OpenMenuButton" is triggered.
 * @hideconstructor
 */
export default function MenuOverlay(): JSX.Element | null {
   const { content, state } = useGlobalData();

   const closeOverlay = (e: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>, force = false) => {
      /* CART-START */
      if ((e.target as HTMLElement).closest('button')?.classList.contains('remove-cart-item-button')) {
         return;
      }
      /* CART-END */

      if (force || (!(e.target as HTMLElement)?.closest('#menu-overlay') && document.getElementById('root')?.hasAttribute('menu-overlay-open'))) {
         /* webpack-strip-block:removed */

         e.preventDefault();
         e.stopPropagation();

         state.set.content({
            ...content,
            menuOverlay: undefined,
         });
         window.removeEventListener('click', closeOverlay);
      }
   };

   useEffect(
      () => {
         if (content.menuOverlay?.type) {
            window.addEventListener('click', closeOverlay);
            const overlay = document.getElementById('menu-overlay') as HTMLElement;
            overlay?.setAttribute('type', content.menuOverlay.type);
         }
         if (content.menuOverlay) {
            document.getElementById('root')?.setAttribute('menu-overlay-open', '');
         } else {
            document.getElementById('root')?.removeAttribute('menu-overlay-open');
         }
      },
      [content.menuOverlay],
   );

   if (!content.menuOverlay) {
      return null;
   }

   return (
      <aside
         id='menu-overlay'
         aria-labelledby='menu-overlay-headline'
      >
         <button
            type='button'
            aria-label='close'
            onClick={(e) => closeOverlay(e, true)}
         >
            <Icon
               icon='close'
            />
         </button>
         <div
            className='inner'
         >
            {content.menuOverlay.content}
         </div>
      </aside>
   );
}
