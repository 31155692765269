import React, { createContext, ProviderProps, useContext, useEffect, useState } from 'react';
import { getUrlParameter } from '..';
/* webpack-strip-block:removed */
import { dispatchChangeSSPLanguageEvent } from '../GlobalContextProvider';
import SettingProps from './interfaces';

import { Language, LANGUAGEKEY } from '../../enums';

// initialize global context - yes it's kinda redundant
// eslint-disable-next-line @typescript-eslint/naming-convention
export const settings: SettingProps = {
   language: {
      value: Language.En,
      // eslint-disable-next-line no-console
      set: () => console.log('no setter defined'),
   },
};

export const SETTINGS = createContext({
   ...settings,
});

/**
 * @class SettingProvider
 */
export default function SettingProvider(props?: ProviderProps<Record<string, unknown>>): JSX.Element {
   const paramLang = getUrlParameter('lccc');
   let initialLang = 0;

   if (paramLang) {
      initialLang = paramLang in LANGUAGEKEY ? LANGUAGEKEY[paramLang] : Language.En || 0;
   } else {
      initialLang = localStorage.getItem('language') ? parseInt(localStorage.getItem('language') || '', 10) : Language.En || 0;
   }

   const [language, setLanguage] = useState<Language>(initialLang);

   /**
    * Saves the language into the indexeddb as well as setting the global setting state
    * @param lang can be found in src/enums.ts
    */
   const setStateLanguage = (lang: Language) => {
      setLanguage(lang);
      dispatchChangeSSPLanguageEvent(lang);
      localStorage.setItem('language', `${lang}`);

      /* webpack-strip-block:removed */
   };

   useEffect(
      () => {
         document.querySelector('html')?.setAttribute('lang', LANGUAGEKEY[language]);
      },
      [language],
   );

   useEffect(
      () => {
         const paramLang = getUrlParameter('lccc');

         if (paramLang) {
            setLanguage(paramLang in LANGUAGEKEY ? LANGUAGEKEY[paramLang] : Language.En || 0);
         } else {
            setLanguage(localStorage.getItem('language') ? parseInt(localStorage.getItem('language') || '', 10) : Language.En || 0);
         }
      },
      [],
   );

   // rendering
   return (
      <SETTINGS.Provider
         value={{
            language: {
               value: language,
               set: setStateLanguage,
            },
         }}
      >
         {props?.children}
      </SETTINGS.Provider>
   );
}

/**
 * @function useSettings
 * @description the global settings as highest ordered context.
 */
export const useSettings = (): SettingProps => useContext<SettingProps>(SETTINGS);
