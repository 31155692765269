import { Breadcrumb } from './ContentHandler/createDataObjectFromSSP/interfaces';
import { makeURLFriendly } from '.';

/**
 * @function getURLByTitleAndBreadcrumb
 * @description returns a url created by title and its breadcrumbs.
 * @param title {string}
 * @param breadcrumbs {Breadcrumb[]}
 */
export default function getURLByTitleAndBreadcrumb(title: string, breadcrumbs: Array<Breadcrumb>): string {
   let url = '';

   breadcrumbs.forEach((breadcrumb) => {
      /* webpack-strip-block:removed */
      /* NON_SPEAKING_URLS-START */
      url = `${url}/${makeURLFriendly(breadcrumb.uid)}`;
      /* NON_SPEAKING_URLS-END */
   });

   return `${url}/${makeURLFriendly(title)}`;
}
