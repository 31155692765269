import { useEffect, useRef } from 'react';

/**
 * Add an eventListener to an element
 * See https://usehooks.com/useEventListener
 * @param eventName Name of the event to add
 * @param handler Function to run when the event occurs
 * @param element Element to add the eventListener to
 */
 export function useEventListener(eventName: string, handler: (...args: Array<any>) => void, element: Element | Window = window) {
   const savedHandler = useRef<(...args: Array<any>) => void>(handler); // Reference storing the handler function

   // Update the current reference if the handler changes (makes the effect always use the latest handler, without having to pass it as an effect dependency)
   useEffect(
      () => {
         savedHandler.current = handler;
      },
      [
         handler,
      ],
   );

   useEffect(
      () => {
         // Do nothing if the element does not support addEventListener
         if (!(element && element.addEventListener)) {
            return undefined;
         }

         const eventListener = (event: Event) => savedHandler.current(event); // EventListener calling the referenced handler function

         element.addEventListener(
            eventName,
            eventListener,
            false,
         );

         // Remove the eventListener on cleanup
         return () => {
            element.removeEventListener(
               eventName,
               eventListener,
               false,
            );
         };
      },
      [
         element,
         eventName,
      ],
   );
}
