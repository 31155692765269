import React from 'react';

import CartCounterIcon from './CartCounterIcon';
import CartOverlayContent from './CartOverlayContent';

import { getTranslation, getUrlParameter, useGlobalData } from '../../util';

export default function OpenCartButton(): JSX.Element | null {
   if (getUrlParameter('cart') === 'false') {
      return null;
   }

   const { content, state } = useGlobalData();

   return (
      <button
         type='button'
         aria-label='open cart'
         className='header-button'
         onClick={() => state.set.content({
            ...content,
            menuOverlay: {
               type: 'cart',
               content: <CartOverlayContent />,
            },
         })}
      >
         <CartCounterIcon />
         <span>{getTranslation()?.header.cart}</span>
      </button>
   );
}
