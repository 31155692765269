import Translations, { Translation } from './translations';

/**
 * @function getTranslation handles retrieving of translations.
 * @param language {string=} definition can be found in src/enums
 * @returns {ObjectLiteral}
 */
export default function getTranslation(language?: string): Translation {
   try {
      // can be used to add specific text for regional purpose
      // switch (document.querySelector('html')?.getAttribute('lang')) {
      //    case 'de-DE':
      //       return Translations['de-DE'];
      // }

      switch (language?.split('-')[0] || document.querySelector('html')?.getAttribute('lang')?.split('-')[0]) {
         case 'de':
            return Translations.de;

         case 'es':
            return Translations.es;

         default:
            return Translations.en;
      }

   } catch (error) {
      console.error(error);
   }

   // code should never reach this. But TypeScript wants this code
   return Translations.en;
}
