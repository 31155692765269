/**
 * @function elementIsVisible
 * @description checks if an HTMLElement is visible to the user.
 * @param elem {HTMLElement}
 * @returns {boolean}
 */
export default function elementIsVisible(elem: HTMLElement): boolean {
   if (!(elem instanceof Element)) {
      throw Error('DomUtil: elem is not an element.');
   }

   const style = getComputedStyle(elem);

   if (
      style.display === 'none' ||
      style.visibility !== 'visible' ||
      style.opacity === '0'
   ) {
      return false;
   }

   if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width === 0) {
      return false;
   }
   const elemCenter   = {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top + elem.offsetHeight / 2,
   };

   if (
      elemCenter.x < 0 ||
      elemCenter.x > (document.documentElement.clientWidth || window.innerWidth) ||
      elemCenter.y < 0 ||
      elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)
   ) {
      return false;
   }

   const pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y) as Element;

   do {
      if (pointContainer === elem) {
         return true;
      }
   } while (pointContainer === pointContainer.parentNode as HTMLElement);

   return false;
}
