import React from 'react';

import { AppInfoProps } from './interfaces';

import './styles.scss';

export default function AppInfo(props: AppInfoProps) {
   return (
      <aside
         className={`appinfo ${props.visible ? 'appinfo--visible' : ''}`}
      >
         <div
            className='appinfo-content'
         >
            <div
               className='appinfo-data'
            >
               <h1>Webplayer</h1>
               <table>
                  <tbody>
                     <tr>
                        <td>Version:</td>
                        <td>{process.env.APP_VERSION}</td>
                     </tr>
                     <tr>
                        <td>Commit:</td>
                        <td>{process.env.WEBPLAYER_COMMIT}</td>
                     </tr>
                     <tr>
                        <td>Date:</td>
                        <td>{process.env.BUILD_DATE}</td>
                     </tr>
                  </tbody>
               </table>
               <h1>Theme</h1>
               <table>
                  <tbody>
                     <tr>
                        <td>Name:</td>
                        <td>{process.env.THEME}</td>
                     </tr>
                     <tr>
                        <td>Version:</td>
                        <td>{process.env.THEME_VERSION}</td>
                     </tr>
                     <tr>
                        <td>Commit:</td>
                        <td>{process.env.THEME_COMMIT}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
         <button
            type='button'
            className='appinfo-close'
            onClick={() => props.closeFn()}
         >
            <span>Close</span>
            <svg
               aria-hidden='true'
            >
               <use href='spritemap.svg#sprite-close'></use>
            </svg>
         </button>
      </aside>
   );
}
