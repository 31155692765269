import MainMenuItem from './ContentHandler/createDataObjectFromSSP/interfaces';
import MasonryItem from '../themes/Buehler/MasonryItem/interfaces';

/**
 * @function findItemByUid
 * @description searches a MainMenuItem with the provided uid. Returns undefined if no MainMenuItem is found, otherwise MainMenuItem.
 * @param receivedItems {MainMenuItem[]}
 * @param uidParam {string} ssp uid
 * @returns {(MainMenuItem | MasonryItem | undefined)}
 */
export default function findItemByUid(receivedItems: Array<MainMenuItem>, uidParam: string): MainMenuItem | MasonryItem | undefined {
   let foundItem: MainMenuItem | MasonryItem | undefined;

   receivedItems?.forEach((item) => {
      if (item.uid === uidParam) {
         foundItem = item;
      }

      if (item.assets) {
         const receivedItem = findItemByUid(item.assets, uidParam);

         if (receivedItem) {
            foundItem = receivedItem;
         }
      }

      if (item.items) {
         const receivedItem = findItemByUid(item.items, uidParam);

         if (receivedItem) {
            foundItem = receivedItem;
         }
      }
   });

   return foundItem;
}
