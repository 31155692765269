import React from 'react';

import { Icon } from '../../components';
import LanguageOverlayContent from './LanguageOverlayContent';

import { getTranslation, getUrlParameter, useGlobalData } from '../../util';

export default function OpenLanguageButton(): JSX.Element | null {
   if (getUrlParameter('langswitch') === 'false') {
      return null;
   }

   const { content, state } = useGlobalData();

   return (
      <button
         type='button'
         aria-label='language menu'
         className='header-button'
         onClick={() => state.set.content({
            ...content,
            menuOverlay: {
               type: 'language',
               content: <LanguageOverlayContent />,
            },
         })}
      >
         <Icon
            icon='globe'
         />
         <span>{getTranslation()?.header.language}</span>
      </button>
   );
}
