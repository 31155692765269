import React, { useEffect, useState } from 'react';
/* webpack-strip-block:removed */

import './styles.scss';
import { makeURLFriendly, removeUrlParameter, useGlobalData } from '../../util';
import { NavLink } from 'react-router-dom';

/**
 * @class Breadcrumbs
 * @classdesc Breadcrumb component which implements https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
 * @hideconstructor
 */
export default function Breadcrumbs(): JSX.Element {
   const { state } = useGlobalData();
   const [breadcrumbs, setBreadcrumbs] = useState<Array<{
      pathname: string;
      search: string;
   }>>([]);

   /**
    * @method Breadcrumbs.handleClick
    * @param e {React.MouseEvent<HTMLAnchorElement, MouseEvent>} https://reactjs.org/docs/events.html#mouse-events
    * @description prevents the default click on the link and triggers a click on the corresponding menu item instead.
    */
   const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      /* webpack-strip-block:removed */
   };

   useEffect(() => {
      const result: Array<{pathname: string, search: string}> = [];

      /* webpack-strip-block:removed */

      /* NON_SPEAKING_URLS-START */
      new Promise<void>((resolve) => {
         const pathString = window.location.search.split('path=')[1]?.split('&')[0].split('%2F');
         pathString?.pop();
         state.value.currentBreadcrumb.forEach((_item, index, array) => {
            result.push({
               pathname: `/?path=${pathString.join('%2F')}${removeUrlParameter(window.location.search, 'path').replace(/\?/g, '&')}`,
               search: '',
            });
            pathString.pop();
            if (index === array.length - 1) {
               resolve();
            }
         });
      }).then(() => {
         setBreadcrumbs(result.reverse());
      });
      /* NON_SPEAKING_URLS-END */
   }, [state.value.currentBreadcrumb]);

   /**
    * @method Breadcrumbs.return
    * @description returns a list of breadcrumbs.
    */
   return (
      <nav aria-label='Breadcrumb' id='breadcrumb'>
         <ol>
            {breadcrumbs?.length > 0 && state.value.currentBreadcrumb?.map((breadcrumb, index) => (
               <li
                  key={index}
               >
                  <NavLink
                     to={breadcrumbs[index] || ''}
                     onClick={handleClick}
                  >
                     {breadcrumb.name}
                  </NavLink>
               </li>
            ))}
            <li>
               <a
                  href={window.location.href}
                  aria-current='page'
               >
                  {state.value.currentMenuItem.name}
               </a>
            </li>
         </ol>
      </nav>
   );
}
