import React from 'react';

import { Picture } from '..';
import { PopupAsset } from '../../util/ContentHandler/createDataObjectFromSSP/interfaces';

/**
 * @class ImageContent
 * @classdesc displays the image content inside of the popup.
 * @hideconstructor
 */
export default function ImageContent(props: PopupAsset): JSX.Element {
   return (
      <Picture {...props.data} />
   );
}
