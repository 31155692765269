import React, { useEffect, useState } from 'react';

import { PopupAsset } from '../../../util/ContentHandler/createDataObjectFromSSP/interfaces';

/**
 * @class iFrameContent
 * @classdesc displays the provided website inside of the popup.
 * @hideconstructor
 */
export default function iFrameContent(props: PopupAsset): JSX.Element {
   const [size, setSize] = useState({
      width: 0,
      height: 0,
   });

   useEffect(() => {
      const popupInner = document.querySelector('#popup .inner') as HTMLElement;
      setSize({
         width: popupInner.clientWidth,
         height: popupInner.clientHeight,
      });
   }, [
      window.innerHeight,
      window.innerWidth,
   ]);

   return (
      <iframe
         src={props.href}
         width={size.width}
         height={size.height}
         frameBorder='0'
         sandbox='' // iframe cannot change anything in this application
      />
   );
}
