import React from 'react';

import './styles.scss';
import { downloadCart } from '../cartStorageHandler';
import { getTranslation } from '../../../util';

export default function DownloadCartButton(): JSX.Element {
   return (
      <button
         type='button'
         id='download-cart-button'
         onClick={downloadCart}
      >
         {getTranslation()?.cart.download_files}
      </button>
   );
}
