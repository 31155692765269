import SSPContentExt from '@ssp/sspcontentext';
import { ObjectLiteral } from '../../interfaces';
import createGlobalDataObjectFromSSP from './createDataObjectFromSSP';
import Translations from './translations';

/**
 * @function ContentHandler
 * @param ssp is the ssp content ext object
 * @description uses the ssp content ext to create the global data object
 * @returns {Promise<void>}
 */
export default async function ContentHandler(ssp: {
   ext: SSPContentExt;
   init: () => Promise<void>;
}): Promise<void> {

   return await createGlobalDataObjectFromSSP(ssp);
}

/**
 * @function getTranslation handles retrieving of translations.
 * @param language definition can be found in src/enums
 * @returns {ObjectLiteral}
 */
export function getTranslation(): ObjectLiteral {
   const language = document.querySelector('html')?.getAttribute('lang');

   try {
      return Translations[language || 'en'];
   } catch (error) {
      console.error(error);
   }

   // code should never reach this. But TypeScript wants this code
   return {};
}
