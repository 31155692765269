/**
 * @function beautifyBytes
 * @description Returns a number in byte converted to a more readable value, e.g. kB
 * @param bytes integer number
 */
export default function beautifyBytes(bytes: number): string {
   /**
    * 1 B  = 1 Byte
    * 1 kB = 1 000 Byte
    * 1 MB = 1 000 000 Byte
    * 1 GB = 1 000 000 000 Byte
    * 1 TB = 1 000 000 000 000 Byte
    */

   if (bytes < 1000) {
      return `${bytes}B`;
   } else if (bytes < 1000000) {
      return `${Math.round(bytes / 1000 * 100) / 100}kB`;
   } else if (bytes < 1000000000) {
      return `${Math.round(bytes / 1000000 * 100) / 100}MB`;
   } else if (bytes < 1000000000000) {
      return `${Math.round(bytes / 1000000000 * 100) / 100}GB`;
   } else {
      return `${Math.round(bytes / 1000000000000 * 100) / 100}TB`;
   }
}
