import React from 'react';

import { TextType, TextVariant } from './enums';
import { Text } from './interfaces';

/**
 * Convert email addresses into email links
 * @param text Text to convert into an email link
 */
function addEmailLinks(text: string): React.ReactNode {
   /* Currently there are no other texts containing "@" than email addresses in the text, so the following regexes are enough to find them. */
   const emailRe = /\S+@\S+/; // Find email addresses
   const emailBracketsRe = /\[\S+@\S+\]/; // Find email addresses in brackets

   return text
      .split(' ')
      .map<React.ReactNode>((txt, index) => {
         if (txt.match(emailBracketsRe)) {
            const address = txt.replace('[', '').replace(']', '');

            return (
               <React.Fragment
                  key={index}
               >
                  <span>[</span>
                  <a
                     href={`mailto:${address}`}
                  >
                     {address}
                  </a>
                  <span>]</span>
               </React.Fragment>
            );
         } else if (txt.match(emailRe)) {
            return (
               <a
                  key={index}
                  href={`mailto:${txt}`}
               >
                  {txt}
               </a>
            );
         } else {
            return (
               <React.Fragment
                  key={index}
               >
                  {txt}
               </React.Fragment>
            );
         }
      })
      .reduce((prev, curr) => [prev, ' ', curr])
   ;
}

/**
 * Split a text into multiple HTML lines by replacing \n with <br />
 * @param text Text to split into multiple lines
 */
function addLinebreaks(text: string): Array<JSX.Element> {
   return text.split('\n').map((txt, index, arr) => {
      const br = index < arr.length - 1 ? <br /> : null;

      return (
         <React.Fragment
            key={index}
         >
            {addEmailLinks(txt)}
            {br}
         </React.Fragment>
      );
   });
}

/**
 * Convert a list of text objects into a list of JSX elements
 * @param texts List of texts to convert
 */
export function textToJSX(texts: Array<Text>): Array<JSX.Element> {
   return texts.map((text, index) => {
      let element: JSX.Element;

      switch (text.type) {
         case TextType.Headline:
            element = React.createElement(
               `h${text.level || 1}`,
               {
                  key: index,
               },
               text.text,
            );
            break;
         case TextType.Paragraph:
            element = (
               <p key={index}>
                  {addLinebreaks(text.text as string)}
               </p>
            );
            break;
         case TextType.List:
            element = React.createElement(
               `${text.variant !== undefined ? TextVariant[text.variant].toLowerCase() : 'ul'}`,
               {
                  key: index,
               },
               Array.isArray(text.text) ? text.text.map((item, liIndex) => <li key={liIndex}>{addEmailLinks(item)}</li>) : <li>{addEmailLinks(text.text)}</li>,
            );
            break;
         default:
            element = (
               <p key={index}>
                  {addLinebreaks(text.text as string)}
               </p>
            );
            break;
      }

      return element;
   });
}
