import React from 'react';

import './styles.scss';
import MasonryItem from './MasonryItem';
import MasonryProps from './interfaces';

/**
 * @class Masonry
 * @classdesc displays the masonry grid in the content area.
 * @hideconstructor
 */
export default function Masonry(props: MasonryProps): JSX.Element {
   return (
      <ul
         id='masonry'
      >
         {props.items?.map((item, index) => (
            // we use the uid as key because when we sort we might run into react errors
            <li
               key={item.uid + index}
               type={item.type}
               data-uid={item.uid}
               data-index={index}
            >
               <MasonryItem
                  {...item}
                  index={index}
               />
            </li>
         ))}
      </ul>
   );
}
