import React, { useRef, useState } from 'react';

import './styles.scss';
import Home from './Home';
import Masonry from './Masonry';
import { useGlobalData } from '../../util';

import { Breadcrumb, Menu, Scrollbar } from '..';
import { Asset } from '../../util/ContentHandler/createDataObjectFromSSP/interfaces';
import MasonryItem from './Masonry/MasonryItem/interfaces';

/**
 * @class Content
 * @classdesc displays the content area of the webplayer.
 * @hideconstructor
 */
export default function Content(): JSX.Element {
   const { state } = useGlobalData();
   const [contentItems, setContentItems] = useState<Array<Asset>>([]);
   const scrollable = useRef(null);

   const handleSetContentItems = (assets: Array<Asset>) => setContentItems(assets);

   return (
      <div
         id='content'
      >
         <Menu setAssets={handleSetContentItems} />
         <div className='content-wrapper'>
            <Scrollbar>
               <main
                  className={`inner${state.value.currentMenuItem.name === 'home' ? ' home' : ''}`}
                  ref={scrollable}
               >
                  {state.value.currentMenuItem.name !== 'home' &&
                     <>
                        <Breadcrumb />
                        <Masonry items={contentItems as unknown as Array<MasonryItem>} />
                     </>
                  }
                  {state.value.currentMenuItem.name === 'home' && <Home />}
               </main>
            </Scrollbar>
         </div>
      </div>
   );
}
