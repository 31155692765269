import React, { useEffect, useState } from 'react';

import './styles.scss';
import CartItem from '../CartItem/interfaces';
import { Icon } from '../../../components';

import { addCartItem, containsCartItem, removeCartItem } from '../cartStorageHandler';
import { getUrlParameter, useGlobalData } from '../../../util';

export default function AddToCartButton(props: CartItem): JSX.Element | null {
   if (getUrlParameter('cart') === 'false') {
      return null;
   }

   const [inCart, setInCart] = useState(false);
   const { state } = useGlobalData();

   const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!inCart) {
         const cartItems = state.value.cartItems;
         cartItems.push(props);
         state.set.pageStateValue({ ...state.value, cartItems });
         addCartItem(props);
      } else {
         removeCartItem(props.uid);
      }
      setInCart(containsCartItem(props.uid));
   };

   useEffect(
      () => {
         const handleChangeCart = () => {
            setInCart(containsCartItem(props.uid));
         };
         handleChangeCart();

         window.addEventListener('change:cart', handleChangeCart);

         return () => {
            window.removeEventListener('change:cart', handleChangeCart);
         };
      },
      [],
   );

   if (process.env.HIDE_SHOPPING_CART_BUTTON && process.env.HIDE_SHOPPING_CART_BUTTON.indexOf(props.type) !== -1) {
      return null;
   }

   return (
      <button
         type='button'
         aria-label='add to cart'
         className={`add-to-cart-button${inCart ? ' in-cart' : ''}`}
         onClick={handleAddToCart}
      >
         <Icon
            icon='cart'
            classes='cart'
         />
         <Icon
            icon='plus'
            classes='plus'
         />
      </button>
   );
}
