import { Component, ErrorInfo } from 'react';
import { Props, State } from './interfaces';

{/* webpack-strip-block:removed */ }

/**
 * @class ErrorBoundary
 * @classdesc catches hard errors and reloads the home page if one reaches it.
 * @hideconstructor
 */
class ErrorBoundary extends Component<Props, State> {
   public state: State = {
      hasError: false,
      error: undefined,
   };

   public static getDerivedStateFromError(error: Error): State {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error };
   }

   public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
      console.error('Uncaught error:', error, errorInfo);
   }

   public render(): React.ReactNode {
      if (this.state.hasError) {
         console.error('ErrorBoundary:', this.state.error);

         {/* webpack-strip-block:removed */ }

         window.open(document.baseURI, '_self');
      }

      return this.props.children;
   }
}

export default ErrorBoundary;
